import styled, { css } from "styled-components"
import Image from "components/atoms/Image"

const textStyles = css`
  color: ${({ theme }) => theme.color.secondary01};
  font-size: ${({ theme }) => theme.font.size.s};
  line-height: 1.71;
  hyphens: auto;
  text-align: justify;

  span[type="button"] {
    color: ${({ theme }) => theme.color.secondary01};
  }
`

const StyledSection = styled.section`
  display: flex;
  flex-direction: column;
  max-width: ${({ theme }) => theme.gridWidth};
  width: 100%;
  height: auto;
  margin: 1.6rem auto;
  padding: 0 ${({ theme }) => theme.layout.mobilePadding};

  ${({ theme }) => theme.mq.medium} {
    flex-direction: row;
    justify-content: space-between;
    height: 57.6rem;
  }
`

const StyledTextItem = styled.div`
  padding: 1.2rem 0;

  ${({ theme }) => theme.mq.medium} {
    padding: 1.2rem 0.6rem 1.2rem 0;
  }

  &:first-child {
    padding-top: 0;
  }
  &:last-child {
    padding-bottom: 0;
  }
`

const StyledTextWrapper = styled.div`
  height: 100%;
  overflow-y: auto;

  ${({ theme }) => theme.mq.medium} {
    margin-right: -0.6rem;
    flex: 0 1 38.8rem;

    &::-webkit-scrollbar-track {
      background-color: ${({ theme }) => theme.color.secondary06};
    }

    &::-webkit-scrollbar {
      width: 6px;
      background-color: ${({ theme }) => theme.color.secondary06};
    }

    &::-webkit-scrollbar-thumb {
      background-color: ${({ theme }) => theme.color.secondary03};
    }
  }
`

const StyledLabel = styled.span`
  display: block;
  font-size: ${({ theme }) => theme.font.size.md};
  font-weight: ${({ theme }) => theme.font.weight.semiBold};
  line-height: 1.75;
  color: ${({ theme }) => theme.color.secondary01};
  padding-bottom: 0.8rem;
`

const StyledListItem = styled.li`
  ${({ order }) => order && `order: ${order};`};
  padding-bottom: 0.8rem;
  text-indent: -20px;
  margin-left: 20px;
`

const StyledListItemText = styled.span`
  ${textStyles};
`

const StyledSliderWrapper = styled.div`
  flex: 0 1 76.8rem;
  margin-right: 1.6rem;
`
const StyledProjectBlock = styled.div`
  display: grid;
  grid-auto-flow: column;
  justify-content: start;
  grid-template-rows: auto;
  margin-bottom: 2.4rem;

  > svg {
    grid-row: 1/3;
    width: 6.4rem;
    height: 6.4rem;
    margin-right: 1.6rem;
  }
`
const StyledBlockLabel = styled.span`
  font-size: ${({ theme }) => theme.font.size.xs};
  font-weight: ${({ theme }) => theme.font.weight.semiBold};
  line-height: 1.67;
  letter-spacing: 0.25px;
  color: ${({ theme }) => theme.color.secondary02};
  margin-top: 0.8rem;
`
const StyledBlockText = styled.span`
  font-size: ${({ theme }) => theme.font.size.s};
  line-height: 1.71;
  color: ${({ theme }) => theme.color.secondary01};
  margin-bottom: 0.8rem;
`
const StyledImg = styled(Image)`
  width: 100%;
  height: 100%;
  margin-bottom: 1.6rem;
`
export {
  textStyles,
  StyledSection,
  StyledTextWrapper,
  StyledTextItem,
  StyledLabel,
  StyledListItem,
  StyledListItemText,
  StyledSliderWrapper,
  StyledProjectBlock,
  StyledBlockLabel,
  StyledBlockText,
  StyledImg,
}

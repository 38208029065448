import styled from "styled-components"

const StyledList = styled.ul`
  display: flex;
  flex-direction: column;
  list-style: square inside;
  padding: 0;
  margin: 0;
  width: auto;
  color: ${({ theme }) => theme.color.main03};

  font-size: ${({ theme }) => theme.font.size.s};
  line-height: 1.71;
`

export { StyledList }

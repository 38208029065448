/* eslint-disable no-unused-expressions */
// This component was based on https://github.com/junkboy0315/react-compare-image

import React, { useEffect, useRef, useState, useCallback } from "react"
import PropTypes from "prop-types"

import trackCustomEventTrigger from "utils/trackCustomEventTrigger"
import trackingCategoriesHelper from "utils/trackingCategoriesHelper"

import Info from "assets/icons/info.svg"
import theme from "styles/theme"

import {
  StyledContainer,
  StyledImageWrapper,
  StyledSlider,
  StyledLine,
  StyledHandle,
  StyledLeftArrow,
  StyledRightArrow,
  StyledLeftLabel,
  StyledRightLabel,
  StyledInfoLabel,
  StyledLabelContainer,
  StyledImg,
} from "./CompareImage.styles"

const CompareImage = (props) => {
  const {
    aspectRatio,
    leftImage,
    leftImageAlt,
    leftImageLabel,
    onSliderPositionChange,
    rightImage,
    rightImageAlt,
    rightImageLabel,
    sliderPositionPercentage,
    infoLabel,
    colors: {
      afterLabelTextColor,
      beforeLabelBackgroundColor,
      beforeLabelTextColor,
      sliderAfterLabelBackgroundColor,
    },
  } = props

  const [sliderPosition, setSliderPosition] = useState(sliderPositionPercentage)
  const [containerWidth, setContainerWidth] = useState(0)
  const [containerHeight, setContainerHeight] = useState(0)
  const [leftImgLoaded, setLeftImgLoaded] = useState(false)
  const [rightImgLoaded, setRightImgLoaded] = useState(false)
  const [shadowPosition, setShadowPosition] = useState("none")

  const containerRef = useRef(null)
  const rightImageRef = useRef(null)
  const leftImageRef = useRef(null)

  useEffect(() => {
    const containerElement = containerRef.current
    // eslint-disable-next-line no-unused-vars, no-undef
    const resizeObserver = new ResizeObserver(([entry, ..._]) => {
      const currentContainerWidth = entry.target.getBoundingClientRect().width
      setContainerWidth(currentContainerWidth)
    })
    resizeObserver.observe(containerElement)

    return () => resizeObserver.disconnect()
  }, [])

  const allImagesLoaded = leftImgLoaded && rightImgLoaded

  useEffect(() => {
    const handleSliding = (event) => {
      const e = event || window.event

      if (event.movementX > 0) {
        setShadowPosition("left")
      } else if (event.movementX < 0) {
        setShadowPosition("right")
      }

      const cursorXfromViewport = e.touches ? e.touches[0].pageX : e.pageX
      const cursorXfromWindow = cursorXfromViewport - window.pageXOffset

      const imagePosition = rightImageRef.current.getBoundingClientRect()
      let pos = cursorXfromWindow - imagePosition.left

      const minPos = 2
      const maxPos = containerWidth - 1

      if (pos < minPos) pos = minPos
      if (pos > maxPos) pos = maxPos

      setSliderPosition(pos / containerWidth)

      if (onSliderPositionChange) {
        onSliderPositionChange(pos / containerWidth)
      }
    }

    const startSliding = (e) => {
      if (!("touches" in e)) {
        e.preventDefault()
      }
      handleSliding(e)
      trackCustomEventTrigger({
        category: trackingCategoriesHelper.PROJECT_VISION,
        action: "Click",
        label: `${trackingCategoriesHelper.PROJECT_VISION} image slider`,
      })

      window.addEventListener("mousemove", handleSliding)
      window.addEventListener("touchmove", handleSliding)
    }

    const finishSliding = () => {
      window.removeEventListener("mousemove", handleSliding)
      window.removeEventListener("touchmove", handleSliding)
      setShadowPosition("none")
    }

    const containerElement = containerRef.current

    if (allImagesLoaded) {
      containerElement.addEventListener("touchstart", startSliding)
      window.addEventListener("touchend", finishSliding)

      containerElement.addEventListener("mousedown", startSliding)
      window.addEventListener("mouseup", finishSliding)

      const leftImageWidthHeightRatio =
        leftImageRef.current.naturalHeight / leftImageRef.current.naturalWidth
      const rightImageWidthHeightRatio =
        rightImageRef.current.naturalHeight / rightImageRef.current.naturalWidth

      const idealWidthHeightRatio =
        aspectRatio === "taller"
          ? Math.max(leftImageWidthHeightRatio, rightImageWidthHeightRatio)
          : Math.min(leftImageWidthHeightRatio, rightImageWidthHeightRatio)

      const idealContainerHeight = containerWidth * idealWidthHeightRatio

      setContainerHeight(idealContainerHeight)
    }

    return () => {
      containerElement.removeEventListener("touchstart", startSliding)
      window.removeEventListener("touchend", finishSliding)
      containerElement.removeEventListener("mousemove", handleSliding)
      containerElement.removeEventListener("mouseleave", finishSliding)
      containerElement.removeEventListener("mousedown", startSliding)
      window.removeEventListener("mouseup", finishSliding)
      window.removeEventListener("mousemove", handleSliding)
      window.removeEventListener("touchmove", handleSliding)
    }
  }, [
    allImagesLoaded,
    aspectRatio,
    containerHeight,
    containerWidth,
    leftImage,
    rightImage,
  ])

  const calculatePosition = useCallback(() => {
    return containerWidth * sliderPosition
  }, [containerWidth, sliderPosition])

  const styles = {
    rightImage: {
      clip: `rect(auto, auto, auto, ${calculatePosition()}px)`,
    },
    leftImage: {
      clip: `rect(auto, ${calculatePosition()}px, auto, auto)`,
    },
    slider: {
      left: `${calculatePosition() - 16}px`,
    },
    rightLabelContainer: {
      clip: `rect(auto, auto, auto, ${calculatePosition()}px)`,
    },
    leftLabelContainer: {
      clip: `rect(auto, ${calculatePosition()}px, auto, auto)`,
    },
  }

  return (
    <>
      <StyledContainer
        containerHeight={containerHeight}
        isVisible={allImagesLoaded}
        ref={containerRef}
        data-testid="container"
      >
        <StyledImageWrapper ref={rightImageRef} style={styles.rightImage}>
          <StyledImg
            afterLoad={() => setRightImgLoaded(true)}
            alt={rightImageAlt}
            data-testid="right-image"
            src={rightImage}
          />
        </StyledImageWrapper>
        <StyledImageWrapper ref={leftImageRef} style={styles.leftImage}>
          <StyledImg
            afterLoad={() => setLeftImgLoaded(true)}
            alt={leftImageAlt}
            data-testid="left-image"
            src={leftImage}
          />
        </StyledImageWrapper>

        <StyledSlider
          style={styles.slider}
          shadowPosition={shadowPosition}
          shadowColor={sliderAfterLabelBackgroundColor}
        >
          <StyledLine sliderLineColor={sliderAfterLabelBackgroundColor} />
          <StyledHandle
            sliderLineColor={sliderAfterLabelBackgroundColor}
            arrowColor={afterLabelTextColor}
          >
            <StyledLeftArrow />
            <StyledRightArrow />
          </StyledHandle>
        </StyledSlider>
        {leftImageLabel && (
          <StyledLabelContainer style={styles.leftLabelContainer}>
            <StyledLeftLabel
              backgroundColor={beforeLabelBackgroundColor}
              textColor={beforeLabelTextColor}
            >
              {leftImageLabel}
            </StyledLeftLabel>
          </StyledLabelContainer>
        )}
        {rightImageLabel && (
          <StyledLabelContainer style={styles.rightLabelContainer}>
            <StyledRightLabel
              backgroundColor={sliderAfterLabelBackgroundColor}
              textColor={afterLabelTextColor}
            >
              {rightImageLabel}
            </StyledRightLabel>
          </StyledLabelContainer>
        )}
        {infoLabel && (
          <StyledInfoLabel>
            <Info />
            {infoLabel}
          </StyledInfoLabel>
        )}
      </StyledContainer>
    </>
  )
}
CompareImage.propTypes = {
  leftImage: PropTypes.oneOfType([
    PropTypes.shape({
      original: PropTypes.string,
      large: PropTypes.string,
      medium: PropTypes.string,
      small: PropTypes.string,
    }),
    PropTypes.string,
  ]).isRequired,
  rightImage: PropTypes.oneOfType([
    PropTypes.shape({
      original: PropTypes.string,
      large: PropTypes.string,
      medium: PropTypes.string,
      small: PropTypes.string,
    }),
    PropTypes.string,
  ]).isRequired,
  aspectRatio: PropTypes.string,
  leftImageAlt: PropTypes.string,
  leftImageLabel: PropTypes.string,
  onSliderPositionChange: PropTypes.func,
  rightImageAlt: PropTypes.string,
  rightImageLabel: PropTypes.string,
  sliderPositionPercentage: PropTypes.number,
  infoLabel: PropTypes.string,
  colors: PropTypes.shape({
    afterLabelTextColor: PropTypes.string,
    beforeLabelBackgroundColor: PropTypes.string,
    beforeLabelTextColor: PropTypes.string,
    sliderAfterLabelBackgroundColor: PropTypes.string,
  }),
}

CompareImage.defaultProps = {
  aspectRatio: "taller",
  leftImageAlt: "",
  leftImageLabel: null,
  onSliderPositionChange: () => {},
  rightImageAlt: "",
  rightImageLabel: null,
  sliderPositionPercentage: 0.5,
  infoLabel: "",
  colors: {
    afterLabelTextColor: theme.color.white,
    beforeLabelBackgroundColor: theme.color.white,
    beforeLabelTextColor: theme.color.secondary01,
    sliderAfterLabelBackgroundColor: theme.color.secondary05,
  },
}

export default CompareImage

import { useIntl } from "gatsby-plugin-intl"

const getProjectTypeDescription = (type, onlyId) => {
  const { formatMessage } = onlyId ? {} : useIntl()

  const typeMap = {
    GROUND_UP: "groundUp",
    VALUE_ADD: "valueAdd",
    ALL_TYPES: "allTypes",
    CORE: "core",
  }
  const id = `projectTypes.${typeMap[type]}`
  return onlyId
    ? id
    : formatMessage({
        id,
      })
}

export default getProjectTypeDescription

import React from "react"
import { graphql } from "gatsby"
import { isEmptyRedirect } from "utils/redirect"
import PropTypes from "prop-types"
import SEO from "components/molecules/SEO"
import Layout from "components/molecules/Layout"
import PortfolioNavigation from "components/molecules/PortfolioNavigation"
import ProjectVision from "components/content/Portfolio/ProjectVision"
import ClientOnly from "components/atoms/ClientOnly"

// TODO add location - link state
const PortfolioVisionPage = ({
  data: {
    api: { project },
  },
  pageContext: { language },
  location,
}) => {
  const { vision } = project || {}
  isEmptyRedirect(vision)

  return (
    <Layout hideFooter isNavStatic language={language}>
      <SEO title="Project vision" />
      {vision && (
        <ClientOnly>
          <PortfolioNavigation projectData={project} location={location} />
          <ProjectVision sectionData={project} />
        </ClientOnly>
      )}
    </Layout>
  )
}

PortfolioVisionPage.propTypes = {
  data: PropTypes.shape({
    api: PropTypes.shape({
      project: PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        slug: PropTypes.string,
        vision: PropTypes.shape({
          afterDescription: PropTypes.string,
          afterImage: PropTypes.oneOfType([
            PropTypes.shape({
              original: PropTypes.string,
              large: PropTypes.string,
              medium: PropTypes.string,
              small: PropTypes.string,
            }),
            PropTypes.string,
          ]),
          beforeDescription: PropTypes.string,
          beforeImage: PropTypes.oneOfType([
            PropTypes.shape({
              original: PropTypes.string,
              large: PropTypes.string,
              medium: PropTypes.string,
              small: PropTypes.string,
            }),
            PropTypes.string,
          ]),
          clusterAmplification: PropTypes.string,
          valuationMultiplier: PropTypes.number,
          valuationMultiplierType: PropTypes.string,
          impactDrivers: PropTypes.arrayOf(
            PropTypes.shape({
              value: PropTypes.string,
            })
          ),
          afterLabelTextColor: PropTypes.string,
          beforeLabelBackgroundColor: PropTypes.string,
          beforeLabelTextColor: PropTypes.string,
          sliderAfterLabelBackgroundColor: PropTypes.string,
        }),
        projectTag: PropTypes.string,
      }),
    }),
  }).isRequired,
  pageContext: PropTypes.shape({
    language: PropTypes.string,
  }).isRequired,
  location: PropTypes.shape({
    state: PropTypes.shape({
      selectedPropertyType: PropTypes.string,
      selectedProjectType: PropTypes.string,
    }),
  }).isRequired,
}

export const query = graphql`
  query ProjectVisionPageData($apiLocale: API_Locale, $projectSlug: String!) {
    api {
      project(slug: $projectSlug, language: $apiLocale) {
        id
        name
        slug
        vision {
          afterDescription
          afterImage
          beforeDescription
          beforeImage
          clusterAmplification
          valuationMultiplier
          valuationMultiplierType
          impactDrivers {
            value
          }
          afterLabelTextColor
          beforeLabelBackgroundColor
          beforeLabelTextColor
          sliderAfterLabelBackgroundColor
        }
        projectTag

        ...projectTabs
      }
    }
  }
`

export default PortfolioVisionPage

import React from "react"
import PropTypes from "prop-types"
import { StyledList } from "./Ul.styles"

const Ul = ({ children, ...rest }) => {
  return <StyledList {...rest}>{children}</StyledList>
}

Ul.propTypes = {
  children: PropTypes.any.isRequired,
}

export default Ul

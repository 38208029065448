import styled, { css, keyframes } from "styled-components"
import { transparentize } from "polished"
import ChevronLeft from "assets/icons/chevron-left.svg"
import ChevronRight from "assets/icons/chevron-right.svg"
import Image from "components/atoms/Image"

const shadowStyles = {
  left: (color) => css`
    left: -1.3rem;
    background: linear-gradient(
      270deg,
      ${transparentize(0.3, color)} 0%,
      ${transparentize(0.92, color)} 90%,
      ${transparentize(1, color)} 100%
    );
  `,
  none: () => css`
    width: 5.8rem;
    background: none;
    left: -1.3rem;
  `,
  right: (color) => css`
    left: 1.6rem;
    background: linear-gradient(
      90deg,
      ${transparentize(0.3, color)} 0%,
      ${transparentize(0.92, color)} 90%,
      ${transparentize(1, color)} 100%
    );
  `,
}

const blurStyles = css`
  content: "";
  height: 100%;
  display: block;
  width: 2.9rem;
  position: absolute;
  top: 0;
`

const imageWrapperStyles = css`
  display: block;
  height: 100%;
  object-fit: cover;
  position: absolute !important;
  width: 100%;
`

const arrowStyles = css`
  width: 1.2rem;
  height: 1.2rem;
`

const bounceRight = keyframes`
    0% {
       transform: translateX(0);
    }
    50% {
       transform: translateX(1rem);
    }
    100% {
       transform: translateX(0);
    }
 `

const bounceLeft = keyframes`
 0% {
    transform: translateX(0);
 }
 50% {
    transform: translateX(-1rem);
 }
 100% {
    transform: translateX(0);
 }
`

const StyledContainer = styled.div`
  box-sizing: border-box;
  position: relative;
  width: 100%;
  height: 100%;
  max-height: 65vh;
  overflow: hidden;

  ${({ containerHeight }) =>
    containerHeight &&
    css`
      height: ${containerHeight}px;
    `}
  ${({ isVisible }) =>
    css`
      display: ${isVisible ? "block" : "none"};
    `}
`

const StyledImageWrapper = styled.div`
  ${imageWrapperStyles}
`

const StyledLeftArrow = styled(ChevronLeft)`
  ${arrowStyles}
`

const StyledRightArrow = styled(ChevronRight)`
  ${arrowStyles}
`

const StyledSlider = styled.div`
  align-items: center;
  cursor: ew-resize;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  position: absolute;
  top: 0;
  width: 3.2rem;
  z-index: 2;

  &:before {
    ${blurStyles}

    ${({ shadowPosition, shadowColor }) =>
      shadowStyles[shadowPosition](shadowColor)}
  }
`

const StyledLine = styled.div`
  ${({ theme }) =>
    css`
      box-shadow: 0px 3px 1px -2px ${transparentize(0.8, theme.color.black)},
        0px 2px 2px 0px ${transparentize(0.86, theme.color.black)},
        0px 1px 5px 0px ${transparentize(0.88, theme.color.black)};
    `};

  flex: 0 1 auto;
  height: 100%;
  width: 4px;

  ${({ sliderLineColor }) =>
    sliderLineColor &&
    css`
      background: ${sliderLineColor};
    `}
`

const StyledHandle = styled.div`
  align-items: center;
  border-radius: 100%;
  position: absolute;
  box-sizing: border-box;
  display: flex;
  flex: 1 0 auto;
  height: 3.2rem;
  justify-content: center;
  width: 3.2rem;
  transform: translateY(-50%);

  ${({ theme }) =>
    css`
      box-shadow: 0px 3px 1px -2px ${transparentize(0.8, theme.color.black)},
        0px 2px 2px 0px ${transparentize(0.86, theme.color.black)},
        0px 1px 5px 0px ${transparentize(0.88, theme.color.black)};
    `};

  ${({ sliderLineColor }) =>
    sliderLineColor &&
    css`
      background: ${sliderLineColor};
    `};

  &:hover {
    ${StyledRightArrow} {
      animation: ${bounceRight} 1s linear;
    }
    ${StyledLeftArrow} {
      animation: ${bounceLeft} 1s linear;
    }
  }

  ${({ arrowColor }) =>
    arrowColor &&
    css`
      ${StyledRightArrow}, ${StyledLeftArrow} {
        fill: ${arrowColor};
      }
    `};
`

const labelStyles = css`
  font-family: ${({ theme }) => theme.font.family.body};
  font-size: ${({ theme }) => theme.font.size.xs};
  font-weight: ${({ theme }) => theme.font.weight.semiBold};
  line-height: 1.67;
  letter-spacing: 0.25px;
  height: 3.2rem;
  box-shadow: 0 0.8rem 1.6rem 0
    ${({ theme }) => transparentize(0.5, theme.color.secondary01)};
  position: absolute;
  top: 1.6rem;
  padding: 0.6rem 1.6rem;

  ${({ backgroundColor }) =>
    backgroundColor &&
    css`
      background: ${backgroundColor};
    `};

  ${({ textColor }) =>
    textColor &&
    css`
      color: ${textColor};
    `};
`

const StyledLeftLabel = styled.span`
  ${labelStyles}
  left: 2.4rem;
`

const StyledRightLabel = styled.span`
  ${labelStyles}
  right: 2.4rem;
`

const StyledInfoLabel = styled.span`
  position: absolute;
  color: ${({ theme }) => theme.color.main03};
  font-size: ${({ theme }) => theme.font.size.s};
  font-weight: ${({ theme }) => theme.font.weight.semiBold};
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1.75;
  left: 50%;
  bottom: 1.6rem;
  transform: translateX(-50%);
  width: fit-content;
  background: ${({ theme }) => theme.color.white};
  padding: 0.8rem 1.6rem;
  z-index: 3;

  > svg {
    min-width: 2.4rem;
    margin-right: 1.6rem;
  }
`
const StyledLabelContainer = styled.div`
  height: 100%;
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
`

const StyledImg = styled(Image)`
  width: 100%;
  height: 100%;
`
export {
  StyledContainer,
  StyledImageWrapper,
  StyledSlider,
  StyledLine,
  StyledHandle,
  StyledLeftArrow,
  StyledRightArrow,
  StyledLeftLabel,
  StyledRightLabel,
  StyledInfoLabel,
  StyledLabelContainer,
  StyledImg,
}

/* eslint-disable no-unused-vars */
import React from "react"
import { useIntl } from "gatsby-plugin-intl"
import PropTypes from "prop-types"

import ProjectType from "assets/icons/project-type.svg"
import getProjectTypeDescription from "utils/getProjectTypeDescription"
import useWindowSize from "utils/useWindowSize"
import getMediaUrl from "utils/getMediaUrl"

import theme from "styles/theme"
import P from "components/atoms/P"
import Ul from "components/atoms/Ul"
import CompareImage from "components/atoms/CompareImage"

import {
  textStyles,
  StyledSection,
  StyledTextWrapper,
  StyledTextItem,
  StyledLabel,
  StyledListItem,
  StyledListItemText,
  StyledSliderWrapper,
  StyledProjectBlock,
  StyledBlockLabel,
  StyledBlockText,
  StyledImg,
} from "./ProjectVision.styles"

const ProjectVision = ({ sectionData }) => {
  const { formatMessage } = useIntl()
  const size = useWindowSize()

  const {
    vision: {
      afterDescription,
      afterImage,
      beforeDescription,
      beforeImage,
      clusterAmplification,
      impactDrivers,
      afterLabelTextColor,
      beforeLabelBackgroundColor,
      beforeLabelTextColor,
      sliderAfterLabelBackgroundColor,
    },
    projectTag,
  } = sectionData

  return (
    <>
      <StyledSection>
        {size.width >= theme.breakpoints.medium && (
          <StyledSliderWrapper>
            <CompareImage
              leftImage={getMediaUrl(beforeImage)}
              rightImage={getMediaUrl(afterImage)}
              sliderPositionPercentage={0.8}
              leftImageLabel={formatMessage({
                id: "pages.portfolio.project.vision.before",
              })}
              rightImageLabel={formatMessage({
                id: "pages.portfolio.project.vision.after",
              })}
              infoLabel={formatMessage({
                id: "pages.portfolio.project.vision.info",
              })}
              colors={{
                afterLabelTextColor,
                beforeLabelBackgroundColor,
                beforeLabelTextColor,
                sliderAfterLabelBackgroundColor,
              }}
            />
          </StyledSliderWrapper>
        )}
        <StyledTextWrapper>
          <StyledProjectBlock>
            <ProjectType />
            <StyledBlockLabel>
              {formatMessage({
                id: "pages.portfolio.project.vision.projectType",
              })}
            </StyledBlockLabel>
            <StyledBlockText>
              {getProjectTypeDescription(projectTag)}
            </StyledBlockText>
          </StyledProjectBlock>
          <StyledTextItem>
            <StyledLabel>
              {formatMessage({
                id: "pages.portfolio.project.vision.amplification",
              })}
            </StyledLabel>
            <P extraStyles={textStyles}>{clusterAmplification}</P>
          </StyledTextItem>
          <StyledTextItem>
            <StyledLabel>
              {formatMessage({
                id: "pages.portfolio.project.vision.drivers",
              })}
            </StyledLabel>
            <Ul>
              {impactDrivers.map((driver) => (
                <StyledListItem key={driver.value}>
                  <StyledListItemText>{driver.value}</StyledListItemText>
                </StyledListItem>
              ))}
            </Ul>
          </StyledTextItem>
          <StyledTextItem>
            {size.width < theme.breakpoints.medium && (
              <StyledImg src={getMediaUrl(beforeImage)} alt="Before image" />
            )}
            <StyledLabel>
              {formatMessage({
                id: "pages.portfolio.project.vision.before",
              })}
            </StyledLabel>
            <P extraStyles={textStyles}>{beforeDescription}</P>
          </StyledTextItem>
          <StyledTextItem>
            {size.width < theme.breakpoints.medium && (
              <StyledImg src={getMediaUrl(afterImage)} alt="After image" />
            )}

            <StyledLabel>
              {formatMessage({
                id: "pages.portfolio.project.vision.after",
              })}
            </StyledLabel>
            <P extraStyles={textStyles}>{afterDescription}</P>
          </StyledTextItem>
        </StyledTextWrapper>
      </StyledSection>
    </>
  )
}

ProjectVision.propTypes = {
  sectionData: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    slug: PropTypes.string,
    vision: PropTypes.shape({
      afterDescription: PropTypes.string,
      afterImage: PropTypes.oneOfType([
        PropTypes.shape({
          original: PropTypes.string,
          large: PropTypes.string,
          medium: PropTypes.string,
          small: PropTypes.string,
        }),
        PropTypes.string,
      ]),
      beforeDescription: PropTypes.string,
      beforeImage: PropTypes.oneOfType([
        PropTypes.shape({
          original: PropTypes.string,
          large: PropTypes.string,
          medium: PropTypes.string,
          small: PropTypes.string,
        }),
        PropTypes.string,
      ]),
      clusterAmplification: PropTypes.string,
      valuationMultiplier: PropTypes.number,
      valuationMultiplierType: PropTypes.string,
      impactDrivers: PropTypes.arrayOf(
        PropTypes.shape({
          value: PropTypes.string,
        })
      ),
      afterLabelTextColor: PropTypes.string,
      beforeLabelBackgroundColor: PropTypes.string,
      beforeLabelTextColor: PropTypes.string,
      sliderAfterLabelBackgroundColor: PropTypes.string,
    }),
    projectTag: PropTypes.string,
  }).isRequired,
}

export default ProjectVision
